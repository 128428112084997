nav {
	ul {
		margin: 0;
		padding: 0;

		li {
			display: block;
			padding: 1em 1em 1em 0;
			float: left;
		}
	}

	.logo {
		height: 1.5em;
		margin: -.25em;
	}
}