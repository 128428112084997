#cases h2 {
	margin-bottom: -1em;
}

.case {
	@media screen and (max-width: 480px) {
		padding-top: 2em;

		img {
			display: block;
			width: 75%;
			margin: auto;
		}
	}

	@media screen and (min-width: 481px) {
		display: flex;
		align-items: center;

		padding-top: 3em;

		&:nth-child(2n + 1) {
			flex-direction: row-reverse;

			.company {
				margin-left: auto;
			}
		}

		.company {
			position: relative;

			max-width: 14em;

			border: 2px solid $accent_color;
			border-radius: 100%;

			background-color: $primary_color;

			&::before {
				content: '';

				display: block;
				padding-top: 100%;
			}

			img {
				position: absolute;
				left: 0; top: 0; right: 0; bottom: 0;

				padding: 15%;
				margin: auto;
			}
		}
	}
}