.mlh-logo {
	position: absolute;
	left: 2em; top: 0;
	z-index: 3;

	width: 6em;
}

.ch-logo {
	position: absolute;
	right: 1em; top: 1em;
	z-index: 3;

	width: 10em;
}

header {
	position: relative;
	z-index: 1;
	
	padding: 8em;

	.figure {
		margin-top: 1em;
		height: 10em;
	}

	.tagline {
		font-size: 1.5em;
	}
}