@import 'background';

@import 'nav';
@import 'header';

@import 'cases';
@import 'winners';
@import 'schedule';
@import 'jury';
@import 'partners';
@import 'crew';

@import 'social';
@import 'footer';

@import 'now';