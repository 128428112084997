.hidden { display: none; }

.alignleft { float: left !important; }
.alignright { float: right !important; }

.left   { text-align: left; }
.center { text-align: center; }
.right  { text-align: right; }
.middle {
	display: flex;
	align-items: center;
}

.wrapper {
	width: 100%;
	max-width: 67.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	margin: auto;
}

.row::after {
	content: '';
	display: block;
	clear: both;
}

.spacing {
	display: block;
	height: 4em;
}

.fullheight {
	height: 100vh;
}

[class*="col-"] {
	display: block;
	float: left;
	box-sizing: border-box;
	min-height: 1px;
	padding: 0 1rem;

	&.nopadding {
		padding: 0;
	}
}

.col-1  { width:   8.33333%; }
.col-2  { width:  16.66666%; }
.col-3  { width:  25%;       }
.col-4  { width:  33.33333%; }
.col-5  { width:  41.66666%; }
.col-6  { width:  50%;       }
.col-7  { width:  58.33333%; }
.col-8  { width:  66.66666%; }
.col-9  { width:  75%;       }
.col-10 { width:  83.33333%; }
.col-11 { width:  91.66666%; }
.col-12 { width: 100%;       }

@media screen and (min-width: 721px) {
	.col-l-1  { width:   8.33333%; }
	.col-l-2  { width:  16.66666%; }
	.col-l-3  { width:  25%;       }
	.col-l-4  { width:  33.33333%; }
	.col-l-5  { width:  41.66666%; }
	.col-l-6  { width:  50%;       }
	.col-l-7  { width:  58.33333%; }
	.col-l-8  { width:  66.66666%; }
	.col-l-9  { width:  75%;       }
	.col-l-10 { width:  83.33333%; }
	.col-l-11 { width:  91.66666%; }
	.col-l-12 { width: 100%;       }

	.left-l   { text-align: left; }
	.center-l { text-align: center; }
	.right-l  { text-align: right; }
	.middle-l {
		display: flex;
		align-items: center;
	}

	.hidden-l { display: none; }
}

@media screen and (min-width: 481px) and (max-width: 720px) {
	.col-m-1  { width:   8.33333%; }
	.col-m-2  { width:  16.66666%; }
	.col-m-3  { width:  25%;       }
	.col-m-4  { width:  33.33333%; }
	.col-m-5  { width:  41.66666%; }
	.col-m-6  { width:  50%;       }
	.col-m-7  { width:  58.33333%; }
	.col-m-8  { width:  66.66666%; }
	.col-m-9  { width:  75%;       }
	.col-m-10 { width:  83.33333%; }
	.col-m-11 { width:  91.66666%; }
	.col-m-12 { width: 100%;       }

	.left-m   { text-align: left; }
	.center-m { text-align: center; }
	.right-m  { text-align: right; }
	.middle-m {
		display: flex;
		align-items: center;
	}

	.hidden-m { display: none; }
}

@media screen and (max-width: 480px) {
	.col-s-1  { width:   8.33333%; }
	.col-s-2  { width:  16.66666%; }
	.col-s-3  { width:  25%;       }
	.col-s-4  { width:  33.33333%; }
	.col-s-5  { width:  41.66666%; }
	.col-s-6  { width:  50%;       }
	.col-s-7  { width:  58.33333%; }
	.col-s-8  { width:  66.66666%; }
	.col-s-9  { width:  75%;       }
	.col-s-10 { width:  83.33333%; }
	.col-s-11 { width:  91.66666%; }
	.col-s-12 { width: 100%;       }

	.left-s   { text-align: left; }
	.center-s { text-align: center; }
	.right-s  { text-align: right; }
	.middle-s {
		display: flex;
		align-items: center;
	}

	.hidden-s { display: none; }
}