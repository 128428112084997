#juryContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.jury {
		flex-shrink: 0;

		margin-bottom: 1em;

		.avatar-container {
			position: relative;
			max-width: 14em;
			margin: auto;

			.avatar,
			.company {
				border: 2px solid $accent_color;
				border-radius: 100%;
			}

			.avatar {
				display: block;
				width: 100%;
			}

			.company {
				position: absolute;
				right: 0em; bottom: 0em;

				width: 40%;
				max-width: 6em;

				background-color: $primary_color;

				&::before {
					content: '';
					display: block;
					padding-top: 100%;
				}

				img {
					position: absolute;
					left: 0; top: 0; right: 0; bottom: 0;

					padding: 15%;
					margin: auto;
				}
			}
		}

		h3 {
			margin-bottom: 0;
			font-size: 1.5em;
		}

		p {
			margin-top: 0;
		}
	}
}