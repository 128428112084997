footer {
	padding: 2em 1.5em 4em;

	position: relative;
	z-index: 3;

	&::before,
	&::after {
		content: '';
		font-size: 0;

		position: absolute;
		left: 0;
		right: 0; bottom: 100%;

		border: transparent solid;
		border-width: 4rem 100vw 0 0;
	}

	&::before {
		padding-bottom: 2px;
		border-right-color: $primary_color;
	}

	&::after {
		border-right-color: $background_color;
	}
}