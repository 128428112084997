.side {
	display: block;
	width: 50%;
	height: 100vh;
	padding: 4em 8.33333%;

	float: left;
	overflow-y: auto;
}

.timer-container {
	font-size: 3em;

	.timer {
		margin-top: -4rem;
		font-size: 9vw;
		font-family: $header_font;
	}
}

.twitter-timeline {
	display: block;

	background-color: $primary_color;
	border-radius: 0;
}
