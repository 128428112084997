#crewContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.member {
		flex-shrink: 0;
		
		img {
			display: block;
			width: 100%;
		}

		h3 {
			margin-bottom: 0;
			font-size: 1.5em;
		}

		p {
			margin-top: 0;
		}
	}
}