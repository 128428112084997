#backgroundCanvas {
	pointer-events: none;

	position: fixed;
	left: 0; top: 0;
	right: 0;

	z-index: 1;

	display: block;
	width: 100vw;
}