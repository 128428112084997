.facebook,
.twitter,
.linkedin,
.email {
	display: inline-block;
	width: 2em;
	height: 2em;
	margin: 1em 1em 4em;

	background: no-repeat center;
	background-size: 100%;

	transition: opacity .2s;

	&:hover {
		opacity: .8;
	}
}

.facebook { background-image: url(/images/facebook.svg); }
.twitter  { background-image: url(/images/twitter.svg);  }
.linkedin { background-image: url(/images/linkedin.svg); }
.email { background-image: url(/images/email.svg); }