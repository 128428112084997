.partner {
	height: 8em;
	padding: 2em;
	display: flex;
	align-items: center;
}

.partner-img {
	vertical-align: middle;
}

