@import 'constants';
@import 'normalize';
@import 'layout';

html {
	overflow-x: hidden;
}

body {
	font-family: $main_font;
	color: $primary_color;
	background-color: $background_color;

	min-height: 100vh;

	-webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $header_font;
}

h2 {
	font-size: 3em;
	text-align: center;

	@media screen and (max-width: 480px) {
		font-size: 2em;
	}
}

h3 {
	font-size: 2em;

	@media screen and (max-width: 480px) {
		font-size: 1.5em;
	}
}

a {
	display: inline-block;
	text-decoration: none;
	color: $accent_color;
}

p {
	line-height: 1.5;
}

.button {
	cursor: pointer;

	padding: .5em;

	border: .2rem solid;
	border-radius: .25rem;

	transition: color .2s, background-color .2s;

	&:hover {
		border-color: $accent_color;

		color: $background_color;
		background-color: $accent_color;
	}
}

.big {
	font-size: 1.5em;
}

.accent {
	background-color: $accent_color;
	color: $background_color;
}

.light {
	background-color: $primary_color;
	color: $background_color;
}

#message404 {
	position: absolute;
	left: 0; top: 0;
	right: 0; bottom: 0;

	height: 9em;
	margin: auto;

	text-align: center;
	font-family: monospace;
	font-weight: bold;
}

main {
	padding-top: 4em;
	background-color: #e0dfe8;
}

section {
	padding: 4em;
	color: $background_color;
}

@import 'components/index';